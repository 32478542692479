<template>
  <div>
   <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg!=null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
           <v-dialog persistent v-model="isLoading">
            <v-row justify="center">
            <v-card width="400" height="100">
              <center class="pa-5">
                Data Importing Form ERP ..<br />
                Please Wait It will Take Time..
              </center>
            </v-card>
            </v-row>
          </v-dialog>
  <div class="display-1 font-weight-light">Learner Divison Synch From ERP</div>
  <v-card class="ma-2 grey lighten-4" elevation="1">
  <v-row class="ma-2">
    
    <v-col  cols="12" sm=3>
        <v-autocomplete
        autocomplete="off"
        aria-autocomplete="off"
        class="ma-2"
        :items="aylist"
        item-text="ay"
        item-value="id"
        label="Select Ay"
        v-model="ay"
        >
        </v-autocomplete>
    </v-col>
  <!--  <v-col cols="12" sm=3>
        <v-autocomplete
        autocomplete="off"
        aria-autocomplete="off"
        class="ma-2"
        :items="programlist"
        item-text="name"
        item-value="id"
        label="Select Program"
        v-model="program_name"
        >
        </v-autocomplete>
    </v-col>
    <v-col cols="12" sm=3>
        <v-select
        autocomplete="off"
        class="ma-2"
        :items="yearlist"
        item-text="name"
        item-value="id"
        label="Select year"
        v-model="year"
        >
        </v-select>
    </v-col>
    <v-col cols="12" sm=3>
        <v-select
        autocomplete="off"
        class="ma-2"
        :items="semesterlist"
        item-text="name"
        item-value="id"
        label="Select Semester"
        v-model="semester"
        >
        </v-select>
    </v-col>-->
    
  </v-row>
  <v-row align="center" justify="center">
    <v-btn class="primary ma-3" @click="synchLearner()"> Synch</v-btn>
  </v-row>
  </v-card>
  
  <v-card class="ma-5">
     <template v-if="learnerdivisiondata!=[]">
                    <v-data-table :headers="headers" :items="learnerdivisiondata" :search="search" class="elevation-1 nowrap">
                        <template v-slot:top>
                            <v-toolbar flat color="white">
                                <v-spacer></v-spacer>
                                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search in table" single-line hide-details></v-text-field>
                            </v-toolbar>
                        </template>
                           
                    </v-data-table>
                </template>
  </v-card>
  
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
        aylist:[],
        programlist:[],
        yearlist:[],
        semesterlist:[],
        learnerdivisiondata:[],
        ay:"",
        program_name:"",
        year:"",
        semester:"",
        search:"",
        isLoading:false,
        snackbar_msg: "",
            snackbar: false,
            color: "",
             headers: [
                { text: "Sr no", value: "srno" },
                 { text: "Academic Year", value: "ay" },
                { text: "PRN", value: "prn" },
                { text: "Name", value: "name" },
                { text: "rollno", value: "rollno" },
                { text: "Year", value: "year" },
                { text: "Division", value: "division" },
                { text: "Program", value: "program" },
                { text: "Semester", value: "Semester" },
            ],
    };
  },
  mounted() {
    this.init();
  },
  methods: {
      synchLearner() {
         
      this.isLoading = true;
      var params={"ay":this.ay,"program_name":this.program_name,"year":this.year,"semester":this.semester}
      axios
        .post("/ImportData/synchlearnerdivision",params)
        .then((res) => {
          if (res.data.msg == "200") {
            this.showSnackbar(
              "green",
              "Learner Division Synchronized Successfully..!"
            );
            this.isLoading = false;
             this.onLoad();
          } else  {
            this.showSnackbar("#b71c1c", res.data.msg);
            this.isLoading = false;
          }
        })
    },
    init() {
         axios.get("Admin/get_ay_sem_year_program_list")
    .then(res =>{
      //window.console.log("res"+res.data)
      if(res.data.msg=="200"){
          this.aylist=res.data.aylist;
          this.programlist=res.data.programlist;
          this.yearlist=res.data.yearlist;
          this.semesterlist=res.data.semesterlist;
          this.learnerdivisiondata=res.data.learnerdivisiondata;
      }
     
    })
    },
     showSnackbar(clr, msg) {
                this.snackbar = true;
                this.color = clr;
                this.snackbar_msg = msg;
            },
  },
};
</script>